<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <form
      class="form-horizontal"
      @submit.prevent="updating"
    >
      <div class="form-group">
        <animal-detail
          :show-detail="true"
          :animal-using="animal"
        />
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="dateOperation">Date de la demande</label>
            <datepicker
              id="dateOperation"
              name="dateOperation"
              input-class="form-control required"
              :disabled-dates="disabledOperationDate"
              format="dd/MM/yyyy"
              placeholder="dd/mm/yyyy"
              v-model="operationDate"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div :class="showCategorie? 'col-md-6':'col-md-12'">
          <div class="form-group">
            <label for="type">Type d'Analyse</label>
            <select
              class="form-control"
              name="type"
              id="type"
              v-select="{placeholder: 'Selectionnez le type d\'analyse'}"
              v-model="type"
            >
              <option value="" />
              <option
                v-for="(t, i) in types"
                :value="t.uid"
                :key="i"
              >
                {{ t.libelle }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="col-md-6"
          v-show="showCategorie"
        >
          <div class="form-group">
            <label for="categorie">Categorie</label>
            <select
              class="form-control"
              name="categorie"
              id="categorie"
              v-select="{placeholder: 'Selectionnez la catégorie d\'analyse'}"
              v-model="categorie"
            >
              <option value="" />
              <option
                v-for="(c, i) in filterdCategories"
                :value="c.uid"
                :key="i"
              >
                {{ c.libelle }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        class="row"
        v-show="showCategorie && showTypeRecherche"
      >
        <div :class="showExamen? 'col-md-6':'col-md-12'">
          <div class="form-group">
            <label for="type-recherche">Type de recherche</label>
            <select
              class="form-control"
              name="type-recherche"
              id="type-recherche"
              v-select="{placeholder: 'Selectionnez le type de recherche'}"
              v-model="typeRecherche"
            >
              <option value="" />
              <option
                v-for="(t, i) in filterTypeRecherches"
                :value="t.uid"
                :key="i"
              >
                {{ t.libelle }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="col-md-6"
          v-show="showExamen"
        >
          <div class="form-group">
            <label for="examen">Examen</label>
            <select
              class="form-control"
              name="examen"
              id="examen"
              v-select="{placeholder: 'Selectionnez l\'examen'}"
              v-model="examen"
            >
              <option value="" />
              <option
                v-for="(e, i) in filterExamens"
                :value="e.uid"
                :key="i"
              >
                {{ e.libelle }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="info">Information Supplementaire</label>
            <textarea
              class="form-control"
              name="infp"
              id="info"
              rows="5"
              v-model="infoSupplementaire"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="loading"
          >
            Mettre à jour
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import Datepicker from 'vuejs-datepicker'
import navbar from '../../../components/navbar.vue'
import AnimalDetail from '../../../components/identification/animal/detailInform.vue'
import { Select } from '../../../directive/select'
import {UPDATE_ANALYSE} from '../../../graphql/sanitaire'
export default {
    components: {AnimalDetail, navbar, Datepicker},
    directives: {Select},
    data(){
        return {
          navbarItems: [
            {
                libelle: 'Sanitaire'
            },
            {
                libelle: 'Examen para-clinique',
                route: 'analyses'
            },
            {
              libelle: 'Modification'
            }
          ],
          pageIcon: 'la-flask',
          pageTitle: 'Modification',
          pageDescription: 'Modifier un examen para-cliniqueen', 
          analyse: null,
          type: null,
          categorie: null,
          typeRecherche: null,
          examen: null,
          infoSupplementaire: null,
          operationDate: new Date()
        }
    },
    beforeMount(){
        this.loadAnalyse()
    },
    watch: {
        analyses: {
            handler(){
                this.loadAnalyse()
            },
            deep: true
        },
        analyse: {
            handler(){
                if(this.analyse !== null) this.init()
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            setSelectedAnimal: 'identification/SET_SELECTED_ANIMAL',
            setLoading: 'SET_LOADING'
        }),
        loadAnalyse(){
          this.analyse = this.analyses.find(item => item.uid === this.$route.params.uid)
        },
        init(){
            this.setSelectedAnimal(this.analyse.animal)
            this.type = this.analyse.type
            this.categorie = this.analyse.categorie
            this.typeRecherche = this.analyse.typeRecherche
            this.examen = this.analyse.examen
            this.infoSupplementaire = this.analyse.infoSupplementaire
            this.operationDate = (this.analyse.operationDate === null)? this.analyse.createdAt : this.analyse.operationDate
        },
        updating(){
          this.setLoading(true)
            let data = {
                animal: this.selectedAnimal,
                type: this.type,
                categorie: this.categorie,
                typeRecherche: this.typeRecherche,
                examen: this.examen,
                infoSupplementaire: this.infoSupplementaire,
                operationDate: this.operationDate
              }
              this.$apollo.mutate({
                mutation: UPDATE_ANALYSE,
                variables: {
                    "uid": this.analyse.uid,
                    "analyse": {
                        ...data
                    }
                },
                update: (data) => {
                    console.log(`Analyse add successfully on uid ${data.updateAnalyse}`)
                }
              }).then(() => {
                this.setLoading(false)
                this.$router.push({name: 'analyses'})
            }).catch((error) => {
                this.setLoading(false)
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            analyses: 'sanitaire/analyses',
            types: 'sanitaire/typeAnalyses',
            categories: 'sanitaire/categorieAnalyses',
            typeRecherches: 'sanitaire/typeRecherches',
            examens: 'sanitaire/examens',
            selectedAnimal: 'identification/selectedAnimal',
            loading: 'loading'
        }),
        showCategorie(){
            if(this.type !== null){
                let type = this.types.find(item => item.uid === this.type)
                if(type)  return type.hasCategorie
                return false
            }
            return false
        },
        animal(){
          if(this.analyse !== null) return this.analyse.animal
          return null
        },
        showTypeRecherche(){
            if(this.categorie !== null){
                let categorie = this.categories.find(item => item.uid === this.categorie)
                if(categorie) return categorie.hasRecherche
                return false
            }
            return false
        },
        showExamen(){
          if(this.typeRecherche !== null){
            let type = this.typeRecherches.find(item => item.uid === this.typeRecherche)
            return type.hasExamen
          }
          return false
        },
        filterdCategories(){
            if(this.type !== null) return [...this.categories].filter(item => item.typeAnalyse === this.type)
            return []
        },
        filterTypeRecherches(){
            if(this.categorie !== null) return [...this.typeRecherches].filter(item => item.categorieAnalyse === this.categorie)
            return []
        },
        filterExamens(){
            if(this.typeRecherche !== null) return [...this.examens].filter(item => item.typeRecherche === this.typeRecherche)
            return []
        }
    }
}
</script>

<style>

</style>